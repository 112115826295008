import { jwtDecode } from 'jwt-decode';

export const isTokenExpired = () => {
  try {
    const token = localStorage.getItem('token');
    if (!token) return false;

    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Convert to seconds

    if (decodedToken.exp < currentTime) {
      // Token is expired - remove it
      localStorage.removeItem('token');
      return true;
    }

    return false;
  } catch (error) {
    // If there's any error (invalid token format, etc), remove token and return true
    localStorage.removeItem('token');
    return true;
  }
};