import React, { useState } from "react";
import {
  TextField,
  Button,
  Typography,
  Box,
  Paper,
  Container,
  Divider,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ClearIcon from "@mui/icons-material/Clear";

const PostAJob = () => {
  const navigate = useNavigate();
  const [linkInputs, setLinkInputs] = useState([""]);
  const [result, setResult] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (index, value) => {
    const newInputs = [...linkInputs];
    newInputs[index] = value;

    // Remove empty fields except the last one
    if (value.trim() === "" && index !== linkInputs.length - 1) {
      newInputs.splice(index, 1);
    }
    // Add new field if user is typing in the last input
    else if (index === linkInputs.length - 1 && value.trim() !== "") {
      newInputs.push("");
    }

    // Ensure we always have at least one input field
    if (newInputs.length === 0) {
      newInputs.push("");
    }

    setLinkInputs(newInputs);
  };

  const handleClearInput = (index) => {
    const newInputs = [...linkInputs];
    newInputs[index] = "";

    // Remove empty fields except the last one
    if (index !== linkInputs.length - 1) {
      newInputs.splice(index, 1);
    }

    // Ensure we always have at least one input field
    if (newInputs.length === 0) {
      newInputs.push("");
    }

    setLinkInputs(newInputs);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setResult(null);

    // Filter out empty links
    const urlList = linkInputs.filter((link) => link.trim());

    try {
      const token = localStorage.getItem("token");

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/scrape/jobs`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            urls: urlList,
          }),
        }
      );

      const data = await response.json();
      setResult(data);
    } catch (error) {
      setResult({ error: error.message });
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Container maxWidth="md">
      <Box sx={{ py: 4 }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 4 }}>
          <IconButton onClick={() => navigate("/jobs")} sx={{ mr: 2 }}>
            <ArrowBackIcon />
          </IconButton>
          <Typography
            variant="h3"
            sx={{
              fontWeight: "bold",
              flex: 1,
              textAlign: "center",
            }}
          >
            Post a Job
          </Typography>
          <Box sx={{ width: 48 }} /> {/* Spacer to balance the layout */}
        </Box>

        <Paper elevation={3} sx={{ p: 4, borderRadius: 2 }}>
          <Typography
            variant="h5"
            gutterBottom
            sx={{ mb: 2, fontWeight: "medium" }}
          >
            Submit Job Listings
          </Typography>

          <Box sx={{ mb: 4, p: 2, borderRadius: 1 }}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                mb: 2,
              }}
            >
              <Typography
                variant="subtitle1"
                sx={{ fontWeight: "medium", color: "primary.main" }}
              >
                Important Information
              </Typography>
              <Button
                variant="outlined"
                size="small"
                sx={{
                  borderRadius: "20px",
                  textTransform: "none",
                  px: 2,
                }}
              >
                Job Posting FAQ
              </Button>
            </Box>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
              • Paste URLs of direct job listings
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
              • All jobs undergo quality filtering to maintain platform
              standards
            </Typography>
            <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
              • Post multiple jobs simultaneously by adding multiple URLs
            </Typography>
            <Typography variant="body2" color="text.secondary">
              • Charges apply only for successfully filtered and posted jobs.
            </Typography>
          </Box>

          <form onSubmit={handleSubmit}>
            {linkInputs.map((link, index) => (
              <Box
                key={index}
                sx={{
                  position: "relative",
                  mb: 2,
                }}
              >
                <TextField
                  fullWidth
                  value={link}
                  onChange={(e) => handleInputChange(index, e.target.value)}
                  placeholder="https://example.com/job"
                  label={`Job URL ${index + 1}`}
                  variant="outlined"
                  sx={{
                    opacity: link.trim() === "" ? 0.6 : 1,
                    transition: "opacity 0.2s ease-in-out",
                  }}
                />
                {link.trim() !== "" && (
                  <IconButton
                    size="small"
                    onClick={() => handleClearInput(index)}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: "50%",
                      transform: "translateY(-50%)",
                      color: "text.secondary",
                      "&:hover": {
                        color: "text.primary",
                      },
                    }}
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                )}
              </Box>
            ))}
            <Button
              type="submit"
              variant="contained"
              disabled={isLoading || !linkInputs.some((link) => link.trim())}
              fullWidth
              size="large"
              sx={{
                mt: 2,
                py: 1.5,
                fontWeight: "bold",
              }}
            >
              {isLoading ? "Processing..." : "Process Jobs"}
            </Button>
          </form>
        </Paper>

        {result && (
          <Paper elevation={3} sx={{ mt: 4, p: 4, borderRadius: 2 }}>
            <Typography variant="h6" gutterBottom>
              Processed Results
            </Typography>
            <Divider sx={{ mb: 3 }} />

            <Box sx={{ mb: 4 }}>
              <Typography
                variant="subtitle1"
                sx={{ mb: 2, fontWeight: "bold" }}
              >
                Summary
              </Typography>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(3, 1fr)",
                  gap: 2,
                }}
              >
                <Paper variant="outlined" sx={{ p: 2, textAlign: "center" }}>
                  <Typography variant="h6" color="primary">
                    {result.summary?.total || 0}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Total Links
                  </Typography>
                </Paper>
                <Paper variant="outlined" sx={{ p: 2, textAlign: "center" }}>
                  <Typography variant="h6" color="success.main">
                    {result.summary?.successful || 0}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Successful
                  </Typography>
                </Paper>
                <Paper variant="outlined" sx={{ p: 2, textAlign: "center" }}>
                  <Typography variant="h6" color="error.main">
                    {result.summary?.errors || 0}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Errors
                  </Typography>
                </Paper>
              </Box>
            </Box>

            <Typography variant="subtitle1" sx={{ mb: 2, fontWeight: "bold" }}>
              Detailed Results
            </Typography>
            <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
              {result.results?.map((item, index) => (
                <Paper
                  key={index}
                  variant="outlined"
                  sx={{
                    p: 2,
                    borderColor:
                      item.status === "success"
                        ? "success.main"
                        : item.status === "filtered"
                        ? "warning.main"
                        : item.status === "rejected"
                        ? "error.main"
                        : "divider",
                  }}
                >
                  <Typography
                    variant="subtitle2"
                    sx={{ wordBreak: "break-all" }}
                  >
                    {item.url}
                  </Typography>
                  <Box
                    sx={{
                      mt: 1,
                      display: "grid",
                      gridTemplateColumns:
                        "repeat(auto-fit, minmax(200px, 1fr))",
                      gap: 2,
                    }}
                  >
                    {item.status && (
                      <Typography variant="body2" color="text.secondary">
                        Status:{" "}
                        <span
                          style={{
                            color:
                              item.status === "success" ? "#2e7d32" : "#d32f2f",
                          }}
                        >
                          {item.status}
                        </span>
                      </Typography>
                    )}
                    {item.title && (
                      <Typography variant="body2" color="text.secondary">
                        Title: {item.title}
                      </Typography>
                    )}
                    {item.company && (
                      <Typography variant="body2" color="text.secondary">
                        Company: {item.company}
                      </Typography>
                    )}
                    {item.message && (
                      <Typography variant="body2" color="error">
                        {item.message}
                      </Typography>
                    )}
                  </Box>
                </Paper>
              ))}
            </Box>
          </Paper>
        )}
      </Box>
    </Container>
  );
};

export default PostAJob;
